<template>
  <div
    v-if="stats"
    class="kpi-row"
  >
    <div
      class="kpi-card kpi-card-light"
      style="padding: 16px"
    >
      <div class="d-flex flex-row justify-content-between">
        Diagnósticos e Maturidade
        <div class="buttons my-0">
          <div
            id="calendar12"
            class="button sm outlined"
          >
            <b-icon icon="calendar3" />
            {{ date1 }} -
            {{ date2 }}
          </div>
        </div>
      </div>
      <b-tabs
        v-model="tabIndex"
        class="mt-4"
        active-nav-item-class="text-primary"
      >
        <b-tab
          v-for="(item, i) in header"
          :key="item.value"
          :title="item.label"
          :active="active === item.value"
          :title-link-class="linkClass(i)"
          @click="active = item.value"
        >
          <b-table-simple
            fixed
            sticky-header="500px"
          >
            <b-thead>
              <b-tr>
                <b-th
                  colspan="3"
                  class="px-0"
                  style="border-color: white; top: -1px"
                >
                  <div style="font-weight: 600">
                    {{ item.label.toUpperCase() }}
                  </div>
                  <div
                    style="font-weight: 600; background-color: #F1F2F4; flex: 1; padding-right: 12px"
                    class="text-right text-secondary py-3 mt-2"
                  >
                    Total
                  </div>
                </b-th>
                <b-th
                  colspan="3"
                  class="px-0"
                  style="border-color: white; top: -1px"
                >
                  <div style="color: #737373; font-size: 11px">
                    EMPRESAS
                  </div>
                  <div class="d-flex justify-content-between mt-4">
                    <div
                      v-b-tooltip.hover
                      style="width: 18px; flex: 1"
                      title="Todas as Empresas"
                    >
                      <b-icon icon="search" />
                    </div>
                    <div
                      v-b-tooltip.hover
                      style="width: 20px; flex: 1 "
                      title="C/ Facilitador"
                    >
                      <b-iconstack font-scale="1.3">
                        <b-icon icon="person" />
                        <b-icon
                          icon="circle-fill"
                          scale="0.7"
                          style="color: white"
                          shift-h="-5"
                          shift-v="-4"
                        />
                        <b-icon
                          icon="circle-fill"
                          scale="0.6"
                          shift-h="-5"
                          shift-v="-4"
                        />
                        <b-icon
                          icon="search"
                          style="color: white"
                          scale="0.35"
                          shift-h="-5"
                          shift-v="-4"
                        />
                      </b-iconstack>
                    </div>
                    <div
                      v-b-tooltip.hover
                      style="width: 20px; flex:1 "
                      title="Em Autodiagnóstico"
                    >
                      <b-iconstack font-scale="1.3">
                        <b-icon icon="building" />
                        <b-icon
                          icon="circle-fill"
                          scale="0.7"
                          style="color: white"
                          shift-h="-5"
                          shift-v="4"
                        />
                        <b-icon
                          icon="circle-fill"
                          scale="0.6"
                          shift-h="-5"
                          shift-v="4"
                        />
                        <b-icon
                          icon="search"
                          style="color: white"
                          scale="0.35"
                          shift-h="-5"
                          shift-v="4"
                        />
                      </b-iconstack>
                    </div>
                  </div>
                  <div
                    style="font-weight: 600; background-color: #F1F2F4; flex: 1"
                    class="text-left text-secondary py-3 d-flex mt-2"
                  >
                    <div
                      v-if="active === 1"
                      style="flex: 1; margin-left: 10.5px"
                    >
                      {{ stats.quizzes_region.reduce((accum,item) => accum + item.companies, 0) }}
                    </div>
                    <div
                      v-if="active === 1"
                      style="flex: 1; margin-left: 10.5px"
                    >
                      {{ stats.quizzes_region_w_facilitator.reduce((accum,item) => accum + item.companies, 0) }}
                    </div>
                    <div
                      v-if="active === 1"
                      style="flex: 1; margin-left: 10.5px"
                    >
                      {{ stats.quizzes_region_wo_facilitator.reduce((accum,item) => accum + item.companies, 0) }}
                    </div>

                    <div
                      v-if="active === 2"
                      style="flex: 1; margin-left: 10.5px"
                    >
                      {{ stats.quizzes_sector.reduce((accum,item) => accum + item.companies, 0) }}
                    </div>
                    <div
                      v-if="active === 2"
                      style="flex: 1; margin-left: 10.5px"
                    >
                      {{ stats.quizzes_sector_w_facilitator.reduce((accum,item) => accum + item.companies, 0) }}
                    </div>
                    <div
                      v-if="active === 2"
                      style="flex: 1; margin-left: 10.5px"
                    >
                      {{ stats.quizzes_sector_wo_facilitator.reduce((accum,item) => accum + item.companies, 0) }}
                    </div>
                  </div>
                </b-th>

                <b-th
                  colspan="3"
                  class="px-0"
                  style="border-color: white; top: -1px"
                >
                  <div style="color: #737373; font-size: 11px">
                    GRAU DE MATURIDADE
                  </div>
                  <div class="d-flex justify-content-between mt-4">
                    <div
                      v-b-tooltip.hover
                      style="width: 25px; flex: 1"
                      title="Todas as Empresas"
                    >
                      <b-iconstack font-scale="1.3">
                        <b-icon
                          icon="globe"
                          shift-h="7"
                        />
                        <b-icon
                          icon="x"
                          shift-h="-5"
                          shift-v="-3"
                        />
                        <b-icon
                          icon="slash"
                          rotate="45"
                          scale="0.6"
                          shift-h="-5"
                          shift-v="2"
                        />
                      </b-iconstack>
                    </div>

                    <div
                      v-b-tooltip.hover
                      style="width: 25px; flex: 1"
                      title="C/ Facilitador"
                    >
                      <b-iconstack font-scale="1.3">
                        <b-icon
                          icon="person"
                          shift-h="7"
                        />
                        <b-icon
                          icon="x"
                          shift-h="-5"
                          shift-v="-3"
                        />
                        <b-icon
                          icon="slash"
                          rotate="45"
                          scale="0.6"
                          shift-h="-5"
                          shift-v="2"
                        />
                      </b-iconstack>
                    </div>
                    <div
                      v-b-tooltip.hover
                      style="width: 30px; flex: 1"
                      title="Em Autodiagnóstico"
                    >
                      <b-iconstack font-scale="1.4">
                        <b-icon
                          icon="x"
                          shift-v="-3"
                        />
                        <b-icon
                          icon="slash"
                          rotate="45"
                          scale="0.6"
                          shift-v="2"
                        />
                      </b-iconstack>
                    </div>
                  </div>
                  <div
                    style="font-weight: 600; background-color: #F1F2F4; flex: 1"
                    class="text-left text-secondary py-3 mt-2 d-flex"
                  >
                    <div
                      v-if="active === 1"
                      style="flex: 1; margin-left: 10.5px"
                    >
                      {{ isNaN(stats.quizzes_region.reduce((accum,item) => accum + item.avg_classification * item.quizzes, 0) / stats.quizzes_region.reduce((accum,item) => accum + item.quizzes, 0)) ? 0 : (stats.quizzes_region.reduce((accum,item) => accum + item.avg_classification * item.quizzes, 0) / stats.quizzes_region.reduce((accum,item) => accum + item.quizzes, 0)).toFixed(2) }}
                    </div>

                    <div
                      v-if="active === 1"
                      style="flex: 1; margin-left: 10.5px"
                    >
                      {{ isNaN(stats.quizzes_region_w_facilitator.reduce((accum,item) => accum + item.avg_classification * item.quizzes, 0) / stats.quizzes_region_w_facilitator.reduce((accum,item) => accum + item.quizzes, 0)) ? 0 : (stats.quizzes_region_w_facilitator.reduce((accum,item) => accum + item.avg_classification * item.quizzes, 0) / stats.quizzes_region_w_facilitator.reduce((accum,item) => accum + item.quizzes, 0)).toFixed(2) }}
                    </div>

                    <div
                      v-if="active === 1"
                      style="flex: 1; margin-left: 10.5px"
                    >
                      {{ isNaN(stats.quizzes_region_wo_facilitator.reduce((accum,item) => accum + item.avg_classification * item.quizzes, 0) / stats.quizzes_region_wo_facilitator.reduce((accum,item) => accum + item.quizzes, 0)) ? 0 : (stats.quizzes_region_wo_facilitator.reduce((accum,item) => accum + item.avg_classification * item.quizzes, 0) / stats.quizzes_region_wo_facilitator.reduce((accum,item) => accum + item.quizzes, 0)).toFixed(2) }}
                    </div>

                    <div
                      v-if="active === 2"
                      style="flex: 1; margin-left: 10.5px"
                    >
                      {{ isNaN(stats.quizzes_sector.reduce((accum,item) => accum + item.avg_classification * item.quizzes, 0) / stats.quizzes_sector.reduce((accum,item) => accum + item.quizzes, 0)) ? 0 : (stats.quizzes_sector.reduce((accum,item) => accum + item.avg_classification * item.quizzes, 0) / stats.quizzes_sector.reduce((accum,item) => accum + item.quizzes, 0)).toFixed(2) }}
                    </div>

                    <div
                      v-if="active === 2"
                      style="flex: 1; margin-left: 10.5px"
                    >
                      {{ isNaN(stats.quizzes_sector_w_facilitator.reduce((accum,item) => accum + item.avg_classification * item.quizzes, 0) / stats.quizzes_sector_w_facilitator.reduce((accum,item) => accum + item.quizzes, 0)) ? 0 : (stats.quizzes_sector_w_facilitator.reduce((accum,item) => accum + item.avg_classification * item.quizzes, 0) / stats.quizzes_sector_w_facilitator.reduce((accum,item) => accum + item.quizzes, 0)).toFixed(2) }}
                    </div>

                    <div
                      v-if="active === 2"
                      style="flex: 1; margin-left: 10.5px"
                    >
                      {{ isNaN(stats.quizzes_sector_wo_facilitator.reduce((accum,item) => accum + item.avg_classification * item.quizzes, 0) / stats.quizzes_sector_wo_facilitator.reduce((accum,item) => accum + item.quizzes, 0)) ? 0 : (stats.quizzes_sector_wo_facilitator.reduce((accum,item) => accum + item.avg_classification * item.quizzes, 0) / stats.quizzes_sector_wo_facilitator.reduce((accum,item) => accum + item.quizzes, 0)).toFixed(2) }}
                    </div>
                  </div>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="active === 1">
              <b-tr
                v-for="i in Object.keys(regions)"
                :key="i"
              >
                <b-td colspan="3">
                  {{ regions[i] }}
                </b-td>
                <b-td>{{ stats.quizzes_region.find(el => el.region === i) ? stats.quizzes_region.find(el => el.region === i).companies : 0 }}</b-td>
                <b-td>{{ stats.quizzes_region_w_facilitator.find(el => el.region === i) ? stats.quizzes_region_w_facilitator.find(el => el.region === i).companies : 0 }}</b-td>
                <b-td>{{ stats.quizzes_region_wo_facilitator.find(el => el.region === i) ? stats.quizzes_region_wo_facilitator.find(el => el.region === i).companies : 0 }}</b-td>

                <b-td>{{ stats.quizzes_region.find(el => el.region === i) ? stats.quizzes_region.find(el => el.region === i).avg_classification.toFixed(2) : '0.00' }}</b-td>
                <b-td>{{ stats.quizzes_region_w_facilitator.find(el => el.region === i) ? stats.quizzes_region_w_facilitator.find(el => el.region === i).avg_classification.toFixed(2) : '0.00' }}</b-td>
                <b-td>{{ stats.quizzes_region_wo_facilitator.find(el => el.region === i) ? stats.quizzes_region_wo_facilitator.find(el => el.region === i).avg_classification.toFixed(2) : '0.00' }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else-if="active === 2">
              <b-tr
                v-for="j in Object.keys(industries)"
                :key="j"
              >
                <b-td colspan="3">
                  <font-awesome-icon
                    :icon="industries[j].icon"
                    class="mr-2 fs-16"
                  />{{ industries[j].text }}
                </b-td>
                <b-td>{{ stats.quizzes_sector.find(el => el.industry === j) ? stats.quizzes_sector.find(el => el.industry === j).companies : 0 }}</b-td>
                <b-td>{{ stats.quizzes_sector_w_facilitator.find(el => el.industry === j) ? stats.quizzes_sector_w_facilitator.find(el => el.industry === j).companies : 0 }}</b-td>
                <b-td>{{ stats.quizzes_sector_wo_facilitator.find(el => el.industry === j) ? stats.quizzes_sector_wo_facilitator.find(el => el.industry === j).companies : 0 }}</b-td>

                <b-td>{{ stats.quizzes_sector.find(el => el.industry === j) ? stats.quizzes_sector.find(el => el.industry === j).avg_classification.toFixed(2) : '0.00' }}</b-td>
                <b-td>{{ stats.quizzes_sector_w_facilitator.find(el => el.industry === j) ? stats.quizzes_sector_w_facilitator.find(el => el.industry === j).avg_classification.toFixed(2) : '0.00' }}</b-td>
                <b-td>{{ stats.quizzes_sector_wo_facilitator.find(el => el.industry === j) ? stats.quizzes_sector_wo_facilitator.find(el => el.industry === j).avg_classification.toFixed(2) : '0.00' }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-tab>
      </b-tabs>
      <b-row
        v-if="stats"
        style="margin-top: 32px;"
      />
    </div>

    <calendar-pick
      target="calendar12"
      :date1="date1"
      :set-date1="(value) => {
        setDate1(value)
      }"
      :date2="date2"
      :set-date2="(value) => {
        setDate2(value)
      }"
      :search="search"
    />
  </div>
</template>

<script>
import CalendarPick from './CalendarPick.vue';

export default
{
  name: 'CardKPIRegionSector',
  components: { 'calendar-pick': CalendarPick },
  props: {
    stats: Object,
    header: Array,
    date1: String,
    setDate1: Function,
    date2: String,
    setDate2: Function,
    search: Function,
  },
  data() {
    return {
      tabIndex: 0,
      active: 1,
      show: false,
      regions: {
        1: 'Alto Minho',
        2: 'Cávado',
        3: 'Ave',
        4: 'Área Metropolitana do Porto',
        5: 'Alto Tâmega',
        6: 'Tâmega e Sousa',
        7: 'Douro',
        8: 'Terras de Trás-os-Montes',
        9: 'Oeste',
        10: 'Região de Aveiro',
        11: 'Região de Coimbra',
        12: 'Região de Leiria',
        13: 'Viseu Dão Lafões',
        14: 'Beira Baixa',
        15: 'Médio Tejo',
        16: 'Beiras e Serra da Estrela',
        17: 'Área Metroplotinada de Lisboa',
        18: 'Alentejo Litoral',
        19: 'Baixo Alentejo',
        20: 'Lezíria do Tejo',
        21: 'Alto Alentejo',
        22: 'Alentejo Central',
        23: 'Algarve',
        24: 'Região Autónoma dos Açores',
        25: 'Região Autónoma da Madeira',
      },
      industries: {
        1: {
          text: 'Agricultura, produção animal, caça, floresta e pesca',
          icon: 'tractor',
        },
        2: {
          text: 'Indústrias extractivas',
          icon: 'hard-hat',
        },
        3: {
          text: 'Indústrias transformadoras',
          icon: 'industry',
        },
        4: {
          text: 'Electricidade, gás, vapor, água quente e fria e ar frio',
          icon: 'charging-station',
        },
        5: {
          text:
            'Captação, tratamento e distribuição de água; saneamento, gestão de resíduos e despoluição',
          icon: 'faucet',
        },
        6: {
          text: 'Construção',
          icon: 'hammer',
        },
        7: {
          text:
            'Comércio por grosso e a retalho; reparação de veículos automóveis e motociclos',
          icon: 'wrench',
        },
        8: {
          text: 'Transportes e armazenagem',
          icon: 'truck',
        },
        9: {
          text: 'Alojamento, restauração e similares',
          icon: 'hotel',
        },
        10: {
          text: 'Actividades de informação e de comunicação',
          icon: 'tty',
        },
        11: {
          text: 'Actividades financeiras e de seguros',
          icon: 'piggy-bank',
        },
        12: {
          text: 'Actividades imobiliárias',
          icon: 'home',
        },
        13: {
          text: 'Actividades de consultoria, científicas, técnicas e similares',
          icon: 'flask',
        },
        14: {
          text: 'Actividades administrativas e dos serviços de apoio',
          icon: 'hands-helping',
        },
        15: {
          text: 'Administração Pública e Defesa; Segurança Social Obrigatória',
          icon: 'shield-alt',
        },
        16: {
          text: 'Educação',
          icon: 'university',
        },
        17: {
          text: 'Actividades de saúde humana e apoio social',
          icon: 'heartbeat',
        },
        18: {
          text:
            'Actividades artísticas, de espectáculos, desportivas e recreativas',
          icon: 'paint-brush',
        },
        19: {
          text: 'Outras actividades de serviços',
          icon: 'taxi',
        },
        20: {
          text:
            'Actividades das famílias empregadoras de pessoal doméstico e actividades de produção das famílias para uso próprio',
          icon: 'users',
        },
        21: {
          text:
            'Actividades dos organismos internacionais e outras instituições extra-territoriais',
          icon: 'globe',
        },
      },
      economic_sector: {
        1: 'Agricultura, produção animal, caça e actividades dos serviços relacionados',
        2: 'Silvicultura e exploração florestal',
        3: 'Pesca e aquicultura',
        5: 'Extracção de hulha e lenhite',
        6: 'Extracção de petróleo bruto e gás natural',
        7: 'Extracção e preparação de minérios metálicos',
        8: 'Outras indústrias extractivas',
        9: 'Actividades dos serviços relacionados com as indústrias extractivas',
        10: 'Indústrias alimentares',
        11: 'Indústria das bebidas',
        12: 'Indústria do tabaco',
        13: 'Fabricação de têxteis',
        14: 'Indústria do vestuário',
        15: 'Indústria do couro e dos produtos do couro',
        16: 'Indústrias da madeira e da cortiça e suas obras, excepto mobiliário; fabricação de obras de cestaria e de espartaria',
        17: 'Fabricação de pasta, de papel, cartão e seus artigos',
        18: 'Impressão e reprodução de suportes gravados',
        19: 'Fabricação de coque, de produtos petrolíferos refinados e de aglomerados de combustíveis',
        20: 'Fabricação de produtos químicos e de fibras sintéticas ou artificiais, excepto produtos farmacêuticos',
        21: 'Fabricação de produtos farmacêuticos de base e de preparações farmacêuticas',
        22: 'Fabricação de artigos de borracha e de matérias plásticas',
        23: 'Fabricação de outros produtos minerais não metálicos',
        24: 'Indústrias metalúrgicas de base',
        25: 'Fabricação de produtos metálicos, excepto máquinas e equipamentos',
        26: 'Fabricação de equipamentos informáticos, equipamento para comunicações e produtos electrónicos e ópticos',
        27: 'Fabricação de equipamento eléctrico',
        28: 'Fabricação de máquinas e de equipamentos, n.e.',
        29: 'Fabricação de veículos automóveis, reboques, semi-reboques e componentes para veículos automóveis',
        30: 'Fabricação de outro equipamento de transporte',
        31: 'Fabricação de mobiliário e de colchões',
        32: 'Outras indústrias transformadoras',
        33: 'Reparação, manutenção e instalação de máquinas e equipamentos',
        35: 'Electricidade, gás, vapor, água quente e fria e ar frio',
        36: 'Captação, tratamento e distribuição de água',
        37: 'Recolha, drenagem e tratamento de águas residuais',
        38: 'Recolha, tratamento e eliminação de resíduos; valorização de materiais',
        39: 'Descontaminação e actividades similares',
        41: 'Promoção imobiliária (desenvolvimento de projectos de edifícios); construção de edifícios',
        42: 'Engenharia civil',
        43: 'Actividades especializadas de construção',
        45: 'Comércio, manutenção e reparação, de veículos automóveis e motociclos',
        46: 'Comércio por grosso (inclui agentes), excepto de veículos automóveis e motociclos',
        47: 'Comércio a retalho, excepto de veículos automóveis e motociclos',
        49: 'Transportes terrestres e transportes por oleodutos ou gasodutos',
        50: 'Transportes por água',
        51: 'Transportes aéreos',
        52: 'Armazenagem e actividades auxiliares dos transportes(inclui manuseamento)',
        53: 'Actividades postais e de courier',
        55: 'Alojamento',
        56: 'Restauração e similares',
        58: 'Actividades de edição',
        59: 'Actividades cinematográficas, de vídeo, de produção de programas de televisão, de gravação de som e de edição de música',
        60: 'Actividades de rádio e de televisão',
        61: 'Telecomunicações',
        62: 'Consultoria e programação informática e actividades relacionadas',
        63: 'Actividades dos serviços de informação',
        64: 'Actividades de serviços financeiros, excepto seguros e fundos de pensões',
        65: 'Seguros, resseguros e fundos de pensões, excepto segurança social obrigatória',
        66: 'Actividades auxiliares de serviços financeiros e dos seguros',
        68: 'Actividades imobiliárias',
        69: 'Actividades jurídicas e de contabilidade',
        70: 'Actividades das sedes sociais e de consultoria para a gestão',
        71: 'Actividades de arquitectura, de engenharia e técnicas afins; actividades de ensaios e de análises técnicas',
        72: 'Actividades de Investigação científica e de desenvolvimento',
        73: 'Publicidade, estudos de mercado e sondagens de opinião',
        74: 'Outras actividades de consultoria, científicas, técnicas e similares',
        75: 'Actividades veterinárias',
        77: 'Actividades de aluguer',
        78: 'Actividades de emprego',
        79: 'Agências de viagem, operadores turísticos, outros serviços de reservas e actividades relacionadas',
        80: 'Actividades de investigação e segurança',
        81: 'Actividades relacionadas com edifícios, plantação e manutenção de jardins',
        82: 'Actividades de serviços administrativos e de apoio prestados às empresas',
        84: 'Administração Pública e Defesa; Segurança Social Obrigatória',
        85: 'Educação',
        86: 'Actividades de saúde humana',
        87: 'Actividades de apoio social com alojamento',
        88: 'Actividades de apoio social sem alojamento',
        90: 'Actividades de teatro, de música, de dança e outras actividades artísticas e literárias',
        91: 'Actividades das bibliotecas, arquivos, museus e outras actividades culturais',
        92: 'Lotarias e outros jogos de aposta',
        93: 'Actividades desportivas, de diversão e recreativas',
        94: 'Actividades das organizações associativas',
        95: 'Reparação de computadores e de bens de uso pessoal e doméstico',
        96: 'Outras actividades de serviços pessoais',
        97: 'Actividades das famílias empregadoras de pessoal doméstico',
        98: 'Actividades de produção de bens e serviços pelas famílias para uso próprio',
        99: 'Actividades dos organismos internacionais e outras instituições extraterritoriais',
      },
      labels: {
        labelPrevDecade: 'Década anterior',
        labelPrevYear: 'Ano anterior',
        labelPrevMonth: 'Mês anterior',
        labelCurrentMonth: 'Mês atual',
        labelNextMonth: 'Próximo mês',
        labelNextYear: 'Próximo ano',
        labelNextDecade: 'Próxima década',
        labelToday: 'Hoje',
        labelSelected: 'Data selecionada',
        labelNoDateSelected: 'Sem data selecionada',
        labelCalendar: 'Calendário',
        labelNav: 'Navegação no calendário',
        labelHelp: 'Use as setas do teclado para navegar pelo calendário',
        labelResetButton: 'Limpar',
        resetButtonVariant: 'primary',
        labelTodayButton: 'Hoje',
        todayButtonVariant: 'primary',
        labelCloseButton: 'Fechar',
      },
    };
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['text-primary'];
      }
      return ['text-secondary'];
    },
  },
};
</script>
