<template>
  <b-popover
    :ref="target"
    :target="target"
    triggers="hover focus click"
    placement="bottom"
  >
    <div
      class="d-flex flex-column"
    >
      <div class="d-flex flex-column">
        Desde:
        <b-form-datepicker
          :value="date1.split('/').reverse().join('-')"
          label-help=""
          boundary="window"
          dropleft
          placeholder="00/00/0000"
          locale="pt"
          label-no-date-selected="Selecionar uma data"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          v-bind="labels"
          :max="date2.split('/').reverse().join('-')"
          @input="(value) => {
            setDate1(value.split('-').reverse().join('/'))
          }"
        />

        Até:
        <b-form-datepicker
          :value="date2.split('/').reverse().join('-')"
          label-help=""
          boundary="window"
          dropleft
          placeholder="00/00/0000"
          locale="pt"
          label-no-date-selected="Selecionar uma data"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          v-bind="labels"
          :min="date1.split('/').reverse().join('-')"
          @input="(value) => {
            setDate2(value.split('-').reverse().join('/'))
          }"
        />
      </div>

      <div class="d-flex flex-column mt-4 mb-2">
        Selecionar datas

        <div class="buttons mt-2 m-0">
          <div
            class="button transparent-dark-primary no-bg no-border m-0 text-left sm px-0"
            style="flex: 1"
            @click="() => {
              lastWeek()
            }"
          >
            Últimos 7 dias
          </div>
        </div>
        <div class="buttons m-0">
          <div
            class="button transparent-dark-primary no-bg no-border m-0 text-left sm px-0"
            style="flex: 1"
            @click="() => {
              lastXMonths(1)
            }"
          >
            Último mês
          </div>
        </div>
        <div class="buttons m-0">
          <div
            class="button transparent-dark-primary no-bg no-border m-0 text-left sm px-0"
            style="flex: 1"
            @click="() => {
              lastXMonths(3)
            }"
          >
            Últimos 3 meses
          </div>
        </div>
        <div class="buttons m-0">
          <div
            class="button transparent-dark-primary no-bg no-border m-0 text-left sm px-0"
            style="flex: 1"
            @click="() => {
              lastXMonths(6)
            }"
          >
            Últimos 6 meses
          </div>
        </div>
        <div class="buttons m-0">
          <div
            class="button transparent-dark-primary no-bg no-border m-0 text-left sm px-0"
            style="flex: 1"
            @click="() => {
              lastYear()
            }"
          >
            Último ano
          </div>
        </div>
        <div
          class="buttons m-0"
        >
          <div
            class="button transparent-dark-primary no-bg no-border m-0 text-left sm px-0"
            style="flex: 1"
            @click="() => {
              currentYear()
            }"
          >
            Ano atual
          </div>
        </div>
      </div>
    </div>

    <div
      class="buttons my-0 mt-2"
    >
      <div
        class="button sm primary text-center"
        style="flex: 1"
        @click="() => {
          $refs[target].$emit('close')
          search()
        }"
      >
        Aplicar
      </div>
    </div>
  </b-popover>
</template>

<script>
export default {
  name: 'CalendarPick',
  props: {
    target: String,
    date1: String,
    date2: String,
    setDate1: Function,
    setDate2: Function,
    search: Function,
  },
  data() {
    return {
      labels: {
        labelPrevDecade: 'Década anterior',
        labelPrevYear: 'Ano anterior',
        labelPrevMonth: 'Mês anterior',
        labelCurrentMonth: 'Mês atual',
        labelNextMonth: 'Próximo mês',
        labelNextYear: 'Próximo ano',
        labelNextDecade: 'Próxima década',
        labelToday: 'Hoje',
        labelSelected: 'Data selecionada',
        labelNoDateSelected: 'Sem data selecionada',
        labelCalendar: 'Calendário',
        labelNav: 'Navegação no calendário',
        labelHelp: 'Use as setas do teclado para navegar pelo calendário',
        labelResetButton: 'Limpar',
        resetButtonVariant: 'primary',
        labelTodayButton: 'Hoje',
        todayButtonVariant: 'primary',
        labelCloseButton: 'Fechar',
      },
    };
  },
  methods: {
    currentYear() {
      this.setDate1(new Date(
        new Date().getFullYear(),
        '00',
        '01',
      ).toLocaleDateString());

      this.setDate2(new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString());
      this.search();
      this.$refs[this.target].$emit('close');
    },

    lastWeek() {
      this.setDate1(new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7,
      ).toLocaleDateString());

      this.setDate2(new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString());
      this.search();
      this.$refs[this.target].$emit('close');
    },

    lastXMonths(x) {
      this.setDate1(new Date(
        new Date().getFullYear(),
        new Date().getMonth() - x,
        new Date().getDate(),
      ).toLocaleDateString());

      this.setDate2(new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString());
      this.search();
      this.$refs[this.target].$emit('close');
    },

    lastYear() {
      this.setDate1(new Date(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString());

      this.setDate2(new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString());
      this.search();
      this.$refs[this.target].$emit('close');
    },
  },
};
</script>
