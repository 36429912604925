var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-popover',{ref:_vm.target,attrs:{"target":_vm.target,"triggers":"hover focus click","placement":"bottom"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" Desde: "),_c('b-form-datepicker',_vm._b({attrs:{"value":_vm.date1.split('/').reverse().join('-'),"label-help":"","boundary":"window","dropleft":"","placeholder":"00/00/0000","locale":"pt","label-no-date-selected":"Selecionar uma data","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"max":_vm.date2.split('/').reverse().join('-')},on:{"input":function (value) {
          _vm.setDate1(value.split('-').reverse().join('/'))
        }}},'b-form-datepicker',_vm.labels,false)),_vm._v(" Até: "),_c('b-form-datepicker',_vm._b({attrs:{"value":_vm.date2.split('/').reverse().join('-'),"label-help":"","boundary":"window","dropleft":"","placeholder":"00/00/0000","locale":"pt","label-no-date-selected":"Selecionar uma data","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"min":_vm.date1.split('/').reverse().join('-')},on:{"input":function (value) {
          _vm.setDate2(value.split('-').reverse().join('/'))
        }}},'b-form-datepicker',_vm.labels,false))],1),_c('div',{staticClass:"d-flex flex-column mt-4 mb-2"},[_vm._v(" Selecionar datas "),_c('div',{staticClass:"buttons mt-2 m-0"},[_c('div',{staticClass:"button transparent-dark-primary no-bg no-border m-0 text-left sm px-0",staticStyle:{"flex":"1"},on:{"click":function () {
            _vm.lastWeek()
          }}},[_vm._v(" Últimos 7 dias ")])]),_c('div',{staticClass:"buttons m-0"},[_c('div',{staticClass:"button transparent-dark-primary no-bg no-border m-0 text-left sm px-0",staticStyle:{"flex":"1"},on:{"click":function () {
            _vm.lastXMonths(1)
          }}},[_vm._v(" Último mês ")])]),_c('div',{staticClass:"buttons m-0"},[_c('div',{staticClass:"button transparent-dark-primary no-bg no-border m-0 text-left sm px-0",staticStyle:{"flex":"1"},on:{"click":function () {
            _vm.lastXMonths(3)
          }}},[_vm._v(" Últimos 3 meses ")])]),_c('div',{staticClass:"buttons m-0"},[_c('div',{staticClass:"button transparent-dark-primary no-bg no-border m-0 text-left sm px-0",staticStyle:{"flex":"1"},on:{"click":function () {
            _vm.lastXMonths(6)
          }}},[_vm._v(" Últimos 6 meses ")])]),_c('div',{staticClass:"buttons m-0"},[_c('div',{staticClass:"button transparent-dark-primary no-bg no-border m-0 text-left sm px-0",staticStyle:{"flex":"1"},on:{"click":function () {
            _vm.lastYear()
          }}},[_vm._v(" Último ano ")])]),_c('div',{staticClass:"buttons m-0"},[_c('div',{staticClass:"button transparent-dark-primary no-bg no-border m-0 text-left sm px-0",staticStyle:{"flex":"1"},on:{"click":function () {
            _vm.currentYear()
          }}},[_vm._v(" Ano atual ")])])])]),_c('div',{staticClass:"buttons my-0 mt-2"},[_c('div',{staticClass:"button sm primary text-center",staticStyle:{"flex":"1"},on:{"click":function () {
        _vm.$refs[_vm.target].$emit('close')
        _vm.search()
      }}},[_vm._v(" Aplicar ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }